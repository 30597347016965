import { stringToDecimalTime } from '../../utils/utils'
import moment from 'moment';
import 'moment/locale/es';

export const getOpenInterval = (start, facility, shiftDuration) => {
    const startMoment = moment(start);
    const startTime = stringToDecimalTime(startMoment.format('HH:mm'));
    const endTime = startTime + shiftDuration / 60;
    const startDayOfWeek = startMoment.day();
    const openingInterval = facility.openingIntervals.find(oIntervals => oIntervals.dayOfWeek === startDayOfWeek);
    if (openingInterval) {
        const interval = openingInterval.intervals.find(interval => stringToDecimalTime(interval.opensAt) <= startTime &&
            endTime <= stringToDecimalTime(interval.closesAt))
        if (interval) {
            return interval;
        }
    }
    return null;
}

export const getFreeShifts = (facility, reservations, start, end, currentDate) => {
    const { openingIntervals, type: { shiftDuration } } = facility;
    const currentDay = currentDate.day();
    const startMoment = moment(start);
    const endMoment = moment(end).subtract(1, 's');
    const startMomentDay = startMoment.day();
    const endMomentDay = endMoment.day();
    let filteredOpeningIntervals = openingIntervals.filter(({ dayOfWeek }) => startMomentDay <= dayOfWeek && dayOfWeek <= endMomentDay);
    if (currentDate.week() === startMoment.week() && currentDate.year() === startMoment.year()) {
        filteredOpeningIntervals = filteredOpeningIntervals.filter(({ dayOfWeek }) => currentDay <= dayOfWeek);
    }

    const shifts = filteredOpeningIntervals.map(openingInterval => {
        const dayOfWeek = openingInterval.dayOfWeek;
        const day = startMoment.clone().day(dayOfWeek);
        const openingIntervalsFreeShifts = openingInterval.intervals.map(interval => {
            const [startingHours, startingMinutes] = interval.opensAt.split(':');
            const [endingHours, endingMinutes] = interval.closesAt.split(':');
            const shiftStart = day.clone().hours(startingHours).minutes(startingMinutes);
            const closesAt = day.clone().hours(endingHours).minutes(endingMinutes);

            if (shiftStart.isSame(currentDate, 'day')) {
                while (shiftStart.isBefore(currentDate, 'minutes')) {
                    shiftStart.add(shiftDuration, 'minutes');
                }
            }

            const shiftEnd = shiftStart.clone().add(shiftDuration, 'minutes');
            const intervalFreeShifts = [];

            while (shiftEnd.isSameOrBefore(closesAt, 'minutes')) {
                if (!reservations.some(reservation => shiftStart.isBetween(reservation.start, reservation.end, 'minutes', '[)') || shiftEnd.isBetween(reservation.start, reservation.end, 'minutes', '(]'))) {
                    const shift = {
                        start: shiftStart.toDate(),
                        end: shiftEnd.toDate(),
                        price: interval.shiftPrice,
                        free: true
                    }
                    intervalFreeShifts.push(shift);
                }
                shiftStart.add(shiftDuration, 'minutes');
                shiftEnd.add(shiftDuration, 'minutes');
            }
            return intervalFreeShifts;
        })
        return openingIntervalsFreeShifts;
    });

    return shifts.flat(2);
}

export const isFacilityOpenOnDate = (facility, date) => {
    if (!facility) return false;
    const dayOfWeek = moment(date).day();
    return facility.openingIntervals.some(openingInterval => openingInterval.dayOfWeek === dayOfWeek);
}