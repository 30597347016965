import { EMAIL_REGEX, NAME_REGEX, POSITIVE_INT_REGEX } from '../../constants/regex';

export default (values) => {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Ingrese el nombre";
    } else if (!NAME_REGEX.test(values.firstName)) {
        errors.firstName = "El nombre debe ser un nombre válido"
    }


    if (!values.lastName) {
        errors.lastName = "Ingrese el apellido";
    } else if (!NAME_REGEX.test(values.lastName)) {
        errors.lastName = "El apellido debe ser un apellido válido"
    }

    if (!values.email) {
        errors.email = "Ingrese el correo electrónico";
    } else if (!EMAIL_REGEX.test(values.email)) {
        errors.email = "El correo electrónico debe ser un correo electrónico válido";
    }

    if (values.phoneNumber && !POSITIVE_INT_REGEX.test(values.phoneNumber)) {
        errors.phoneNumber = "El teléfono debe ser un número entero positivo";
    }

    return errors;
}