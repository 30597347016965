export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^[\p{L} ]+$/ui;
export const NAME_WITH_NUMBERS_REGEX = /^[\p{L}\d ]+$/ui;
export const NAME_WITH_DASH_REGEX = /^[\p{L}\d -]+$/ui;
export const POSITIVE_INT_REGEX = /^\d+$/;
export const POSITIVE_NUMBER_REGEX = /^\d+([.]\d+)?$/;
export const ADDRESS = /.+(,.+){1,2}/;
export const TIME_OF_DAY_24_HOURS_REGEX = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
export const DNI_REGEX = /^[0-9]{7,8}$/;
export const LETTERS_NUMBERS_SPACES_POINTS_REGEX = /^[\p{L}\d \.]+$/ui;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const ROLE_REGEX = /^([a-zA-Z]+?)+$/;
export const HEX_COLOR = /#[0-9a-zA-Z]{6}/;