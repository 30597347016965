import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import LoginContainer from "./pages/Login/LoginContainer";
import OnboardingContainer from "./pages/Onboarding/OnboardingContainer";
import Home from "./pages/Home";
import { getCookie } from "./utils/utils";
import PasswordReset from "./pages/PasswordReset/PasswordResetForm/PasswordResetFormContainer";
import SignupContainer from "./pages/Signup/SignupContainer";

export default props => {
    const isLogged = getCookie('isLogged');
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/onboarding" component={OnboardingContainer} />
                <Route exact path="/login" component={LoginContainer} />
                <Route exact path="/passwordreset" component={PasswordReset} />
                <Route exact path="/signup" component={SignupContainer} />
                <Route path="/home" component={Home} />
                <Route
                    path="/"
                    render={() => (
                        isLogged ?
                            <Redirect to="/home" /> :
                            <Redirect to="/onboarding" />
                    )}
                />
                <Route path="/404" component={() => (<div>404</div>)} />
                <Redirect from='*' to='/404' />
            </Switch>
        </BrowserRouter>
    );
}