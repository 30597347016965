import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateInvitee from './validator';
import * as Swal from "sweetalert2";
import InviteeFormComponent from './InviteeFormComponent';
import moment from 'moment';
import 'moment/locale/es';
import { getChangedValues } from '../../../utils/utils';

export const FIELDS = {
    firstName: "Nombre",
    lastName: "Apellido",
    dni: "DNI",
    date: "Fecha de concurrencia",
    attended: "Asistió",
    inviter: "Invitante"
};

const INITIAL_STATE = {
    _id: '',
    firstName: "",
    lastName: "",
    dni: "",
    date: moment(),
    attended: false,
    inviter: null
};

const InviteeFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleChanges,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues,
        handleDateChange
    } = useFormValidation(INITIAL_STATE, values => validateInvitee(values));
    const isNewInvitee = Object.entries(data).length === 0;

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/invitees');

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
            return;
        } else if (props.location.state) {

            const stateKeys = Object.keys(INITIAL_STATE);
            const { state } = props.location

            const validKeys = {}

            //copy only valid fields
            stateKeys.forEach(k => {
                if (state.hasOwnProperty(k))
                    if (k === "date")
                        validKeys[k] = moment(state[k])
                    else
                        validKeys[k] = state[k]
            });

            if (Object.keys(validKeys).length === stateKeys.length) {
                setData(validKeys)
                resetValues(validKeys);
                setLoading(false);
                return;
            }
        }

        handleClose();
    }, [props, resetValues]);


    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar Invitado",
            text: `¿Estás seguro de que querés eliminar el invitado '${values.firstName}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar invitado',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`invitees/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/invitees',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {

        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewInvitee) {

                const body = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    dni: values.dni,
                    date: values.date.toISOString(),
                    inviter: values.inviter._id
                }

                post('invitees', body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/invitees',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const changedValues = getChangedValues(data, values);
                if (changedValues.length === 0) {
                    props.history.push('/home/invitees');
                    return;
                }

                const body = {}

                changedValues.forEach(value => {
                    Object.assign(body, { [value]: values[value] });
                });

                patch(`invitees/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/invitees',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    const onConfirmInvitee = () => {
        props.history.push({
            pathname: '/home/invitees',
            state: { msg: 'edited' },
        });
    }

    const isEditable = () => moment().isSameOrBefore(values.date, 'day') && !values.attended

    return (
        <InviteeFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewInvitee={isNewInvitee}
            isEditable={isEditable}
            match={props.match}
            setErrors={setErrors}
            onConfirmInvitee={onConfirmInvitee}
            handleChanges={handleChanges}
            handleDateChange={handleDateChange}
        />
    );
};

export default InviteeFormContainer;