import { NAME_REGEX } from '../../../constants/regex';

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Ingrese el título";
    } else if (!NAME_REGEX.test(values.name)) {
        errors.name = "El nombre sólo puede contener letras o espacios"
    }

    return errors;
}