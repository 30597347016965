import React from "react";
import { TextField, withStyles } from "@material-ui/core";

import { FormLayout } from "../../common";
import { FIELDS } from "./SignupInvitationsFormContainer";
import styles from "./styles";

const SignupInvitationsFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleSubmit,
        submitting,
        values
    } = props;

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={() => {}}
            loading={false}
            editMode={true}
            submitting={submitting}
            handleSubmit={handleSubmit}
            isNew={false}
            customTitle="Enviar e-mails de invitación a registrarse"
        >
            <TextField
                name='emailAddresses'
                error={errors.emailAddresses !== undefined}
                helperText={errors.emailAddresses}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailAddresses}
                label={FIELDS.emailAddresses}
                className={classes.textField}
                InputProps={{
                    readOnly: false,
                    disableUnderline: false
                }}
                multiline={true}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(SignupInvitationsFormComponent);