import { ROLE_REGEX } from "../../../constants/regex";

export default (values, permissions) => {
    let errors = {};

    if (!values.role) {
        errors.role = "Ingrese el nombre del rol";
    } else if (values.role.length < 2 || values.role.length > 20) {
        errors.role = "El nombre debe contener entre 2 y 20 letras";
    } else if (!ROLE_REGEX.test(values.role)) {
        errors.role = "El nombre debe contener solo letras";
    }

    if (!permissions.length) {
        errors.permissions = 'Debe elegir al menos un permiso';
    }

    return errors;
}