import React, { useState, useEffect } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './utils/state/store';
import { useSelector } from 'react-redux';

const INITIAL_THEME = {
    palette: {
        secondary: {
            main: '#326273'
        },
        primary: {
            main: '#28536B',
            contrastText: '#FFFFFF'
        },
        state: {
            success: "#1B9F20",
            failure: "#FF0000"
        },
        clubstar: {
            main: '#28536B',
            contrastText: '#FFFFFF'
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['"Roboto"', 'sans-serif'].join(','),
        useNextVariants: true
    }
};

const copyInitialTheme = () => ({
    ...INITIAL_THEME,
    palette: {
        ...INITIAL_THEME.palette,
        primary: { ...INITIAL_THEME.primary },
        secondary: { ...INITIAL_THEME.secondary }
    }
});

let initialMuiTheme = createMuiTheme(INITIAL_THEME);

initialMuiTheme = responsiveFontSizes(initialMuiTheme);

function ThemeProvider() {
    const club = useSelector((state) => state);
    const [muiTheme, setMuiTheme] = useState(initialMuiTheme);
    useEffect(() => {
        if (club) {
            const { primaryColor, secondaryColor } = club;
            let theme = copyInitialTheme();
            primaryColor && (theme.palette.primary.main = primaryColor);
            secondaryColor && (theme.palette.secondary.main = secondaryColor);
            theme = createMuiTheme(theme);
            setMuiTheme(responsiveFontSizes(theme));
        } else {
            setMuiTheme(initialMuiTheme);
        }

    }, [club]);

    return (
        <MuiThemeProvider theme={muiTheme}>
            <Routes />
        </MuiThemeProvider>
    );
}

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider />
        </Provider>
    );
}

export default App;
