import { validateNameWithNumbers } from "../../../utils/utils";
import { POSITIVE_NUMBER_REGEX } from '../../../constants/regex';

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Ingrese el nombre";
    } else validateNameWithNumbers(values, errors);

    if (!values.branch) {
        errors.branch = "Debe elegir la sede de las Instalaciones de este Tipo";
    }

    if (typeof values.detailImage == "object" && !values.detailImage.type.includes("image/")) {
        errors.detailImage = "Debe cargar una imagen"
    }

    if (values.newShiftPrice) {
        if (!POSITIVE_NUMBER_REGEX.test(values.newShiftPrice)) {
            errors.newShiftPrice = "El precio del turno debe ser un número no negativo."
        }
    }

    return errors;
}