// import moment from 'moment';
// import 'moment/locale/es';

export default (values) => {
    let errors = {};

    if (!values.event) {
        errors.event = "Ingrese el título";
    }

    if (!values.description) {
        errors.description = "Ingrese la descripción";
    }

    if (!values.eventDate) {
        errors.eventDate = "Seleccione una fecha";
    }
    // else if (moment(values.eventDate) < moment())
    //     errors.eventDate = "La fecha no puede ser anterior al día de hoy";

    if (!values.branch) {
        errors.branch = "Seleccione una sede";
    }

    if (!values.topics.length) {
        errors.topics = "Ingrese los tópicos";
    }

    return errors;
}