import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import { handleResponse, get, swal500, patch } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";
import NewParticipantDialog from './NewParticipantDialog';
import Swal from 'sweetalert2';

const MESSAGES = {
    created: 'Participante agregado correctamente',
    deleted: 'El participante será eliminado en el próximo período de cobro'
};

class ActivityParticipantsTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            participants: [],
            participantsCount: 0,
            page: 0,
            loading: true,
            updating: false,
            successMessage: '',
            showDialog: false,
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        if (!id)
            this.props.history.push('/home/activities/')

        this.getParticipants(id);

        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    getParticipants = (id) => {
        return get(`activities/${id}/participants`)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {

                this.setState({
                    participants: parsed.message.map((p) => ({ ...p, isPendingCancellation: p.isPendingCancellation ? "Si" : "No" })),
                    loading: false,
                    updating: false
                })
            }
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    handleChangePage = () => { };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = () => this.setState({ showDialog: true })

    setDeletable = ({ isPendingCancellation }) => isPendingCancellation === "No" && this.props.location.state.isEditable

    setEditable = () => false

    closeDialog = () => this.setState({ showDialog: false })

    handleDelete = participantId => {
        const activityId = this.props.match.params.id;
        const participant = this.state.participants.find(u => u._id === participantId);
        const { palette } = this.props.theme;

        Swal.fire({
            title: "Eliminar participante",
            text: `¿Estás seguro de que querés eliminar el participante '${participant.firstName} ${participant.lastName}'? El socio quedará en espera a ser removido de la actividad cuando se facture el próximo período.`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, cancelar',
            confirmButtonText: 'Sí, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        patch(`activities/${activityId}/leave`, { user_id: participant._id })
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        participants: prevState.participants.filter(r => r._id !== participantId),
                                        participantsCount: prevState.participantsCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getParticipants(activityId))
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    addParticipant = () => {
        const activityId = this.props.match.params.id;

        this.setState({ loading: true, submitting: true }, () => {
            this.getParticipants(activityId)
                .then(() => {
                    this.setState({ successMessage: MESSAGES.created, showDialog: false })
                })
        });
    }

    render() {
        return (
            <div>
                <TableComponent
                    data={this.state}
                    path=""
                    newMessage="Nuevo participante"
                    columns={COLUMNS}
                    dataKey="participants"
                    handleDelete={this.handleDelete}
                    handleOpenForm={this.handleOpenForm}
                    handleChangePage={this.handleChangePage}
                    onCloseToast={this.onCloseToast}
                    successMessage={this.state.successMessage}
                    setDeletable={this.setDeletable}
                    setEditable={this.setEditable}
                    setViewable={this.setEditable}
                    ROWS_PER_PAGE={20}
                    showPagination={false}
                    canAddNew={this.props.location.state.isEditable}
                />
                <NewParticipantDialog
                    dialogOpen={this.state.showDialog}
                    closeDialog={this.closeDialog}
                    onNewParticipant={this.addParticipant}
                    activityId={this.props.match.params.id}
                />
            </div>

        )
    }
}

export default withTheme(ActivityParticipantsTableContainer);
