import React from "react";
import {
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Typography,
    withStyles
} from "@material-ui/core";
import styles from "./styles";
import { ArrowBack } from "@material-ui/icons";
import Loading from "../Loading";

const FormLayout = (props) => {
    const {
        classes,
        handleClose,
        renderActions,
        loading,
        editMode,
        submitting,
        handleSubmit,
        isNew,
        dataKey,
        customTitle,
        children
    } = props;

    const getTitle = () => {
        if (customTitle) {
            return customTitle;
        }
        if (isNew) {
            return `Crear ${dataKey}`;
        }
        if (editMode) {
            return `Editar ${dataKey}`;
        }
        return `Datos ${dataKey}`;
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.container}>
                <div className={classes.formHeader}>
                    <div className={classes.titleContainer} >
                        <IconButton onClick={handleClose}>
                            <ArrowBack/>
                        </IconButton>
                        <Typography style={{ fontSize: 20 }}>
                            {getTitle()}
                        </Typography>
                    </div>
                    {renderActions()}
                </div>
                {loading ? (
                    <Loading/>
                ) : (
                    <form className={classes.form}>
                        {children}
                        <div className={classes.actionsContainer}>
                            {
                                editMode &&
                                <>
                                    {
                                        submitting &&
                                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }}/>
                                    }
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={loading || submitting}
                                        onClick={handleSubmit}
                                    >
                                        Confirmar
                                    </Button>
                                </>
                            }
                        </div>
                    </form>
                )
                }
            </Paper>
        </div>
    );
};

export default withStyles(styles)(FormLayout);