import React, { useState } from 'react';

import { handleResponse, post, swal500 } from '../../../utils/network';
import { useFormValidation } from "../../common";
import SignupInvitationsFormComponent from "./SignupInvitationsFormComponent";
import validateEmailAddresses from './validator';

export const FIELDS = {
    emailAddresses: 'Direcciones de e-mail (separe con espacios en blanco o renglones)'
};

const INITIAL_STATE = {
    emailAddresses: '',
    parsedEmailAddresses: []
};

const SignupInvitationsFormContainer = (props) => {
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors
    } = useFormValidation(INITIAL_STATE, values => validateEmailAddresses(values));

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                errors[field.name] = field.message.replaceAll(field.name, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/users');

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            post('auth/batch-signup-invitation', { emailAddresses: values.parsedEmailAddresses })
                .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                .then(res => {
                    setSubmitting(false);
                    props.history.push({
                        pathname: '/home/users',
                        state: { msg: 'invitedForSignup' },
                    });
                })
                .catch(err => {
                    setSubmitting(false);
                    if (err !== 400) {
                        swal500(err);
                    }
                });
        }
    };

    return (
        <SignupInvitationsFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleSubmit={handleResult}
            submitting={submitting}
            values={values}
        />
    );
};

export default SignupInvitationsFormContainer;