import React, { Component } from 'react'
import { get, patch, handleResponse, swal500, post, getUrl } from '../../utils/network';
import ProfileFormComponent from './ProfileFormComponent';
import validateUser from './validator';
import { getCookie, objectIsEmpty } from '../../utils/utils';

export const FIELDS = {
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    phoneNumber: "Número de Celular",
    publicPhoneNumber: "Visibilidad de mi número en el buscador*"
};

export default class ProfileFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                publicPhoneNumber: false
            },
            errors: {},
            initialValues: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                publicPhoneNumber: false
            },
            userId: '',
            imageUrl: '',
            loading: true,
            editMode: false,
            successMessage: '',
            errorMessage: '',
        };
    };

    componentDidMount() {
        this.getUser();
    };

    getUser = () => {
        const userId = getCookie('userId');
        get(`users/${userId}`)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                const { firstName, lastName, email, phoneNumber, publicPhoneNumber } = parsed.message;
                const newValues = {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    publicPhoneNumber
                };
                this.setState({
                    values: newValues,
                    initialValues: newValues,
                    userId,
                    imageUrl: getUrl(`images/user/${userId}`),
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                swal500(err);
            });
    };

    resetError = name => this.setState({ errors: { ...this.state.errors, [name]: undefined } });

    handleChange = event => {
        const { name, value } = event.target;
        this.resetError(name);
        this.setState({
            values: {
                ...this.state.values,
                [name]: value
            }
        });
    };

    handleBlur = event => {
        const { name } = event.target;
        const validationErrors = validateUser(this.state.values);
        this.setState({ errors: { ...this.state.errors, [name]: validationErrors[name] } });
    };

    handleSubmit = event => {
        event.preventDefault();
        const validationErrors = validateUser(this.state.values);
        this.setState({ errors: validationErrors });
        return objectIsEmpty(validationErrors);
    };

    getChangedValues = () => {
        const changedValues = [];
        Object.keys(this.state.initialValues).forEach(key => {
            this.state.initialValues[key] !== this.state.values[key] && changedValues.push(key)
        });
        return changedValues.filter(v => v !== 'created');
    };

    handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            this.setState({ errors });
        });
        return Promise.reject(400);
    };

    handleResult = (e) => {
        e.preventDefault();
        const formValid = this.handleSubmit(e);
        if (formValid) {
            this.setState({ submitting: true });
            const body = {};
            const changedValues = this.getChangedValues();
            if (changedValues.length === 0) {
                this.setState({ submitting: false, editMode: false });
                return;
            }
            changedValues.forEach(value => {
                Object.assign(body, { [value]: this.state.values[value] });
            });
            patch(`users/${this.state.userId}/own`, body)
                .then(res => handleResponse(res, this.props, [{ status: 400, method: this.handle400 }]))
                .then(res => {
                    this.setState(prevState => ({
                        submitting: false,
                        editMode: false,
                        initialValues: {
                            ...prevState.initialValues,
                            ...body
                        },
                        successMessage: 'Datos actualizados correctamente',
                    }));
                })
                .catch(err => {
                    this.setState({ submitting: false });
                    if (err !== 400) {
                        swal500(err);
                    }
                });
        }
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleUploadImage = (e) => {
        const file = e.target.files[0];
        if (file.type.includes("image/")) {
            const newUrl = URL.createObjectURL(file);
            this.setState({ submittingImage: true });
            const formData = new FormData();
            formData.append('profilepicture', file);
            post(`users/${this.state.userId}/profilepicture`, formData, true, { 'Accept': 'Application/json' }, true)
                .then(res => handleResponse(res, this.state))
                .then(res => {
                    this.setState({
                        submittingImage: false,
                        imageUrl: newUrl,
                        successMessage: 'Imagen actualizada correctamente',
                    });
                })
                .catch(e => {
                    this.setState({ submittingImage: false });
                    swal500(e);
                });
        }
        else {
            this.setState({ errorMessage: 'El archivo debe ser de tipo imagen' });
        }
    }

    render() {
        const { loading, submitting, submittingImage, values, errors, editMode, imageUrl } = this.state;
        return (
            <ProfileFormComponent
                errors={errors}
                handleChange={this.handleChange}
                handleBlur={this.handleBlur}
                handleResult={this.handleResult}
                handleUploadImage={this.handleUploadImage}
                loading={loading}
                submitting={submitting}
                submittingImage={submittingImage}
                values={values}
                editMode={editMode}
                setEditMode={(value) => this.setState({ editMode: value })}
                imageUrl={imageUrl}
                successMessage={this.state.successMessage}
                errorMessage={this.state.errorMessage}
                onCloseToast={this.onCloseToast}
            />
        );
    };
};
