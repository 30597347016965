import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, withStyles } from '@material-ui/core';
import { handleResponse, patch, swal500 } from '../../../../utils/network';
import styles from './styles'

const ConfirmInviteeDialog = (props) => {

    const {
        handleChange,
        handleChanges,
        handleBlur,
        values,
        errors,
        setErrors,
        submitting,
        classes
    } = props;

    const [internalSubmitting, setInternalSubmitting] = useState(false);
    const inviteeID = props.match.params.id;

    const doPost = () => {
        const handle400 = err => {
            err.text().then(error => {
                let errorObject = JSON.parse(error);
                let errors = {};
                errors["invitationPrice"] = errorObject.fields[0].message;
                setErrors(errors);
            });
            return Promise.reject(400);
        };

        patch(`invitees/${inviteeID}/attend`, { price: values.invitationPrice })
            .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
            .then(result => {

                handleChanges({
                    invitationPrice: null
                })
                setErrors({
                    invitationPrice: null
                })

                props.onConfirmInvitee();

            })
            .catch(err => {
                if (err !== 400)
                    swal500(err);
            })
            .then(() => {
                setInternalSubmitting(false)
            });
    }

    const validateAndSend = () => {

        if (!values.invitationPrice) {
            setErrors({
                invitationPrice: "Ingrese el precio de la invitación"
            })
            return;
        }

        setInternalSubmitting(true);
        doPost();
    }

    return (
        <Dialog open={props.dialogOpen} onClose={props.closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Confirmar asistencia</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Estás confirmando la asistencia del invitado. Por favor ingresá el precio correspondiente de la invitación:
                </DialogContentText>
                <TextField
                    name='invitationPrice'
                    error={errors.invitationPrice !== undefined}
                    helperText={errors.invitationPrice}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.invitationPrice}
                    label="Precio de invitación"
                    type="number"
                    InputProps={{
                        readOnly: internalSubmitting || submitting
                    }}
                    className={classes.fullWidthInput}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.closeDialog} color="primary">
                    Cancelar
                </Button>
                <Button onClick={validateAndSend} color="primary" disabled={internalSubmitting || submitting}>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(ConfirmInviteeDialog);