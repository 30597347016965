import {
    Button,
    Grid,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import React from "react";
import { CustomPassword } from "../../components/common";
import { clubsDef } from "../Onboarding/OnboardingContainer";
import { FIELDS } from "./SignupContainer";
import styles from './styles';

const SignupComponent = ({
    classes,
    clubShortName,
    errors,
    handleChange,
    handleDateChange,
    handleBlur,
    handleResult,
    submitting,
    values,

}) => (
    <div className={classes.background}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.gridSignUp}
        >
            <form className={classes.formSignup} onSubmit={handleResult}>
                <div
                    className={classes.formHeader}
                >
                    <img
                        src={clubsDef[clubShortName]?.logoPath}
                        width={140}
                        height={130}
                        alt={`Escudo de ${clubShortName}`}
                        style={{ margin: '0 auto' }}
                    />
                </div>
                <Typography
                    component="h1"
                    variant="h5"
                    className={classes.title}
                >
                    Ingrese sus datos
                </Typography>
                <TextField
                    name='email'
                    value={values.email}
                    label={FIELDS.email}
                    className={classes.textField}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                />
                <TextField
                    name='firstName'
                    error={errors.firstName !== undefined}
                    helperText={errors.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    label={FIELDS.firstName}
                    className={classes.textField}
                />
                <TextField
                    name='lastName'
                    error={errors.lastName !== undefined}
                    helperText={errors.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    label={FIELDS.lastName}
                    className={classes.textField}
                />
                <TextField
                    name="birthdate"
                    label={FIELDS.birthdate}
                    type="date"
                    className={classes.textField}
                    error={errors.birthdate !== undefined}
                    helperText={errors.birthdate}
                    margin="dense"
                    FormHelperTextProps={{ margin: 'dense' }}
                    defaultValue={values.birthdate?.format('YYYY-MM-DD')}
                    onChange={handleDateChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true
                    }}

                />
                <TextField
                    name='memberNumber'
                    error={errors.memberNumber}
                    helperText={errors.memberNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.memberNumber}
                    label={FIELDS.memberNumber}
                    type="number"
                    disabled={submitting}
                    className={classes.textField}

                />
                <TextField
                    name='phoneNumber'
                    error={errors.phoneNumber !== undefined}
                    helperText={errors.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    label={FIELDS.phoneNumber}
                    type="number"
                    disabled={submitting}
                    className={classes.textField}
                />
                <CustomPassword
                    name='password'
                    error={errors.password}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    label={FIELDS.password}
                    className={classes.textField}
                    hideStartAdornment
                    InputProps={{
                        autoComplete: 'new-password'
                    }}
                />
                <CustomPassword
                    name='confirmPassword'
                    error={errors.confirmPassword}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    label={FIELDS.confirmPassword}
                    className={classes.textField}
                    hideStartAdornment
                    InputProps={{
                        autoComplete: 'new-password'
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.confirmButton}
                    onClick={handleResult}
                >
                    Confirmar
                </Button>
            </form>
        </Grid>
    </div>
);

export default withStyles(styles)(SignupComponent);