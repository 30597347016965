import {
    Button,
    FormHelperText,
    Grid,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import React from 'react';
import styles from "./styles";

const OnboardingComponent = ({
    classes,
    club,
    error,
    handleChange,
    handleSubmit }) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.gridOnboarding}
        >
            <form className={classes.formOnboarding}>
                <img
                    src={'/assets/clubstar_logo.png'}
                    width={322.61}
                    height={111.21}
                    alt="Logo de Clubstar"
                    style={{ margin: '0 auto' }}
                />
                <Typography
                    component="h1"
                    variant="h5"
                    className={classes.title}
                >
                    Ingresá a tu Club
                </Typography>
                <div className={classes.submitContainer}>
                    <div className={classes.clubContainer}>
                        <div className={classes.clubInputContainer}>
                            <TextField className={classes.clubTextField}
                                size='medium'
                                inputProps={{ className: classes.clubDomInput }}
                                InputProps={{ classes: { input: classes.clubInput } }}
                                value={club}
                                onChange={handleChange}
                            />
                            <Typography
                                variant="caption"
                                className={classes.clubInputLabel}
                            >
                                .app.clubst.ar
                            </Typography>
                        </div>
                    </div>
                    <Button
                        color='primary'
                        variant="contained"
                        type="submit"
                        fullWidth
                        onClick={handleSubmit}
                        className={classes.button}
                    >
                        Ingresar
                    </Button>
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </div>
            </form>
        </Grid>
    )
};
export default withStyles(styles)(OnboardingComponent);