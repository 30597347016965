import React, { useState } from "react";
import {
    IconButton,
    withStyles,
    TextField,
    Tooltip
} from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon, Check } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./InviteeFormContainer";
import { ApiAutocomplete, FormLayout } from "../../common";
import moment from 'moment';
import 'moment/locale/es';
import ConfirmInviteeDialog from "./ConfirmInviteeDialog/ConfirmInviteeDialog";

const InviteeFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewInvitee,
        isEditable,
        handleDateChange
    } = props;

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!isNewInvitee ?
                isEditable() ?
                    <div>
                        {values.date.isSameOrBefore(moment(), 'day') &&
                            <Tooltip title="Confirmar asistencia del invitado">
                                <IconButton onClick={() => setShowConfirmDialog(true)}>
                                    <Check />
                                </IconButton>
                            </Tooltip>
                        }
                        {!editMode &&
                            <IconButton onClick={() => setEditMode(true)}>
                                <CreateIcon />
                            </IconButton>
                        }
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                    :
                    values.attended ?
                        <p>El invitado ya asistió</p>
                        :
                        <Tooltip title="Confirmar asistencia del invitado">
                            <IconButton onClick={() => setShowConfirmDialog(true)}>
                                <Check />
                            </IconButton>
                        </Tooltip>
                : null
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewInvitee}
            dataKey="Invitado"
        >
            <TextField
                name='firstName'
                error={errors.firstName !== undefined}
                helperText={errors.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                label={FIELDS['firstName']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='lastName'
                error={errors.lastName !== undefined}
                helperText={errors.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                label={FIELDS['lastName']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='dni'
                error={errors.dni !== undefined}
                helperText={errors.dni}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dni}
                label={FIELDS['dni']}
                type="number"
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name="date"
                label="Fecha de concurrencia"
                type="date"
                // className={classes.date}
                error={errors.date !== undefined}
                helperText={errors.date}
                margin="dense"
                FormHelperTextProps={{ margin: 'dense' }}
                defaultValue={values.date.format('YYYY-MM-DD')}
                onChange={handleDateChange}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode || submitting
                }}
            />
            {editMode && <div className={classes.divider} />}
            <ApiAutocomplete
                error={errors.inviter}
                handleChange={handleChange}
                label={(user) => `${user.firstName} ${user.lastName}`}
                name='inviter'
                path='users?email=like:'
                title={FIELDS['inviter']}
                value={values.inviter}
                noOptionsText="No hay socios con ese correo electrónico"
                filterLabel={(value) => value.email}
                dataField="data"
                disabled={submitting || !editMode || !isNewInvitee}
            />

            <ConfirmInviteeDialog
                {...props}
                dialogOpen={showConfirmDialog}
                closeDialog={() => setShowConfirmDialog(false)}
                classes={null}
            />

        </FormLayout>
    );
};

export default withStyles(styles)(InviteeFormComponent);