import React from "react";
import { IconButton, Checkbox, FormControlLabel, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./DisciplineFormContainer";
import { FormLayout } from "../../common";

const DisciplineFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleCheckBox,
        handleBlur,
        handleClose,
        handleResult,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewDiscipline,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewDiscipline}
            dataKey="Disciplina"
        >
            <TextField
                name='name'
                error={errors.name !== undefined}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                label={FIELDS['name']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={!editMode}
                        checked={values.competitive}
                        onChange={handleCheckBox}
                        name='competitive'
                    />
                }
                label={FIELDS['competitive']}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(DisciplineFormComponent);