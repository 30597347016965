import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del, get } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateEvents from './validator';
import EventFormComponent from "./EventFormComponent";
import * as Swal from "sweetalert2";
import moment from 'moment';
import 'moment/locale/es';
import { getChangedValues } from '../../../utils/utils';

export const FIELDS = {
    event: "Título",
    description: "Descripción",
    eventDate: 'Fecha',
    branch: 'Sede',
    topics: "Tópicos"
};

const INITIAL_STATE = {
    event: '',
    description: '',
    eventDate: moment(),
    branch: null,
    topics: []
};

const EventFormContainer = (props) => {
    const [data, setData] = useState({});
    const [branches, setBranches] = useState([]);
    const [topics, setTopics] = useState([]);
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(false);
    const [loadingBranches, setLoadingBranches] = useState(true);
    const [loadingTopics, setLoadingTopics] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const isNewEvent = Object.entries(data).length === 0;

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        handleDateChange,
        values,
        errors,
        setErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateEvents(values, isNewEvent));

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    useEffect(() => {
        if (!props.location.state) {
            props.history.push('/home/events');
        } else if (props.match.params.id === 'new') {
            const { slotInfo } = props.location.state;
            setEditMode(true);
            resetValues({ ...INITIAL_STATE, eventDate: moment(slotInfo.start).toDate() });
            setLoading(false);
        } else {
            const { event } = props.location.state;
            setData(event)
            resetValues(event);
            setLoading(false);
        }
    }, [props, resetValues]);


    useEffect(() => {
        let branchesCount;
        let responseBranches = [];

        const getBranches = (page) => {
            setLoadingBranches(true);
            get(`branches?page=${page}`)
                .then(res => handleResponse(res, props))
                .then(parsed => {
                    setLoadingBranches(false);
                    if (parsed.error) props.history.push('/home/events');
                    else {
                        if (parsed.message.count) {
                            branchesCount = parsed.message.count;
                        }
                        if (parsed.message.branches.length) {
                            responseBranches = responseBranches.concat(parsed.message.branches);
                        }

                        if (responseBranches.length < branchesCount) {
                            getBranches(page + 1);
                        }
                        else
                            setBranches(responseBranches);
                    }
                })
                .catch(err => {
                    setLoadingBranches(false);
                    swal500(err);
                });
        }
        getBranches(0);
    }, [props]);

    useEffect(() => {
        let topicsCount;
        let responseTopics = [];

        const getTopics = (page) => {
            setLoadingTopics(true);
            get(`topics?page=${page}`)
                .then(res => handleResponse(res, props))
                .then(parsed => {
                    setLoadingTopics(false);
                    if (parsed.error) props.history.push('/home/events');
                    else {
                        if (parsed.message.count) {
                            topicsCount = parsed.message.count;
                        }
                        if (parsed.message.data.length) {
                            responseTopics = responseTopics.concat(parsed.message.data);
                        }

                        if (responseTopics.length < topicsCount) {
                            getTopics(page + 1);
                        }
                        else
                            setTopics(responseTopics);
                    }
                })
                .catch(err => {
                    setLoadingTopics(false);
                    swal500(err);
                });
        }
        getTopics(0);
    }, [props]);

    const handleClose = () => props.history.push('/home/events');

    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar evento",
            text: `¿Estas seguro de que queres eliminar el evento ${values.event}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar evento',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`events/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/events',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewEvent) {
                post('events', {
                    title: values.event,
                    description: values.description,
                    eventDate: values.eventDate,
                    branch: values.branch._id,
                    topics: values.topics.map(topic => topic._id)
                })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/events',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const body = {};
                const changedValues = getChangedValues(data, values);
                if (changedValues.length === 0) {
                    props.history.push('/home/events');
                    return;
                }
                changedValues.forEach(value => {
                    Object.assign(body, { [value]: values[value] });
                });
                if (body.event) {
                    body.title = body.event
                    delete body.event
                }
                if (body.branch)
                    body.branch = body.branch._id;
                if (body.topics)
                    body.topics = body.topics.map(topic => topic._id);
                patch(`events/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/events',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <EventFormComponent
            errors={errors}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            loadingBranches={loadingBranches}
            loadingTopics={loadingTopics}
            submitting={submitting}
            values={values}
            allBranches={branches}
            allTopics={topics}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewEvent={isNewEvent}
        />
    );
};

export default EventFormContainer;