import React from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    FormLabel,
    CircularProgress,
    FormHelperText
} from "@material-ui/core/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";

const CustomAccordion = ({ category, handleChangeCategory, handleChangePermission, editMode }) => {
    const allPermissionsChecked = category.permissions.every(p => p.checked);
    const somePermissionChecked = category.permissions.some(p => p.checked);
    return (editMode || somePermissionChecked) && (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {editMode ?
                    <FormControlLabel
                        control={
                            <Checkbox
                                indeterminate={somePermissionChecked && !allPermissionsChecked}
                                checked={allPermissionsChecked}
                                onChange={handleChangeCategory}
                                name={category.category}
                            />
                        }
                        label={category.category}
                    />
                    :
                    <FormLabel>{category.category}</FormLabel>
                }
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    {category.permissions.map(permission => (editMode || permission.checked) && (
                        (permission.disabled && editMode) ? (
                            <Tooltip title="Permiso asociado al rol" placement="right" key={permission._id}>
                                <FormControlLabel
                                    key={permission._id}
                                    control={
                                        <Checkbox
                                            disabled={!editMode || permission.disabled}
                                            checked={permission.checked ? permission.checked : false}//for controlled component
                                            onChange={handleChangePermission}
                                            name={permission.name}
                                        />
                                    }
                                    label={permission.description}
                                />
                            </Tooltip>
                        ) : (
                            <FormControlLabel
                                key={permission._id}
                                control={
                                    <Checkbox
                                        disabled={!editMode || permission.disabled}
                                        checked={permission.checked ? permission.checked : false}//for controlled component
                                        onChange={handleChangePermission}
                                        name={permission.name}
                                    />
                                }
                                label={permission.description}
                            />
                        ))
                    )}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    );
};

export default function PermissionsSelector({ permissions = [], loading, handleChangeCategory, handleChangePermission, editMode, error }) {
    const somePermissionChecked = permissions
        .filter(category => category.permissions.some(p => p.checked)).length > 0;
    return (
        <div>
            {
                (somePermissionChecked || editMode) &&
                <div style={{ margin: "20px 0 15px 0" }} >
                    <Typography style={{ fontSize: 16 }} {...(error && { color: 'error' })} >
                        Permisos asignados
                    </Typography>
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </div>
            }
            {
                loading ? (
                    <div style={{ display: 'flex', alignItems: 'center', padding: "10px 0" }}>
                        <CircularProgress color="primary" size={20} />
                        <span style={{ paddingLeft: 10 }}>Obteniendo permisos...</span>
                    </div>
                ) : (
                    permissions.length > 0 ? (
                        permissions.map((category, index) => (
                            <CustomAccordion
                                key={index}
                                category={category}
                                handleChangeCategory={handleChangeCategory(category.category)}
                                handleChangePermission={handleChangePermission(category.category)}
                                editMode={editMode}
                            />
                        ))
                    ) : (
                        <Typography>No posee permisos</Typography>
                    )
                )
            }
        </div>
    );
}
