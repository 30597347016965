import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";
import config from '../../../utils/config';

const MESSAGES = {
    created: 'El Tipo de Instalación ha sido creado correctamente',
    edited: 'El Tipo de Instalación ha sido editado correctamente',
    deleted: 'El Tipo de Instalación ha sido eliminado correctamente'
};

class FacilityTypesTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            facilityTypes: [],
            facilityTypesCount: 0,
            loading: true,
            updating: false,
            successMessage: ''
        }
    }

    componentDidMount() {
        this.getfacilityTypes();
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    addImageToData = (facilityTypes) => facilityTypes.map(m => ({ ...m, detailImage: `${config.backEndUrl}images/facilityType/${m._id}` }))

    getfacilityTypes = () => {
        get('facility-types?app=bof&page=0')
            .then(res => handleResponse(res, this.props))
            .then(parsed => {

                this.setState({
                    facilityTypes: this.addImageToData(parsed.message.facilityTypes),
                    facilityTypesCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            }
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                get('facility-types?app=bof&page=' + page)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            facilityTypes: prevState.facilityTypes.concat(this.addImageToData(parsed.message.facilityTypes)),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id, stateToSend) => id ?
        this.props.history.push({
            pathname: `/home/facilityTypes/${id}`,
            state: stateToSend
        })
        :
        this.props.history.push('/home/facilityTypes/new');

    handleDelete = facilityTypeId => {
        const facilityType = this.state.facilityTypes.find(u => u._id === facilityTypeId);
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar Tipo de Instalación",
            text: `¿Estás seguro de que querés eliminar el Tipo de Instalación '${facilityType.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar Tipo de Instalación',
            confirmButtonText: 'Sí, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`facility-types/${facilityType._id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        facilityTypes: prevState.facilityTypes.filter(r => r._id !== facilityTypeId),
                                        facilityTypesCount: prevState.facilityTypesCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getfacilityTypes())
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    navStateSelector = ({ _id, name, shiftDuration, detailImage, branch, requiredMembershipType }) =>
        ({ _id, name, shiftDuration, detailImage, branch, requiredMembershipType })

    render() {
        return (
            <TableComponent
                data={this.state}
                path="/home/facilityTypes/"
                newMessage="Nuevo Tipo de Instalación"
                columns={COLUMNS}
                dataKey="facilityTypes"
                handleOpenForm={this.handleOpenForm}
                handleDelete={this.handleDelete}
                handleChangePage={this.handleChangePage}
                onCloseToast={this.onCloseToast}
                successMessage={this.state.successMessage}
                navStateMapper={this.navStateSelector}
            />
        )
    }
}

export default withTheme(FacilityTypesTableContainer);
