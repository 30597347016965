export default (values) => {
    let errors = {};

    if (!values.question) {
        errors.question = "Ingrese la pregunta";
    }

    if (!values.answer) {
        errors.answer = "Ingrese la respuesta";
    }

    return errors;
}