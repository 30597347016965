import moment from 'moment';
import 'moment/locale/es';
import { NAME_REGEX, EMAIL_REGEX, POSITIVE_INT_REGEX, PASSWORD_REGEX } from '../../../constants/regex';

const ROLE_PERMISSIONS_ERROR_MESSAGE = 'Debe elegir un rol o al menos un permiso';

export default (values, permissions, isNewUser) => {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Ingrese el nombre";
    } else if (!NAME_REGEX.test(values.firstName)) {
        errors.firstName = "El nombre debe ser un nombre válido"
    }

    if (!values.lastName) {
        errors.lastName = "Ingrese el apellido";
    } else if (!NAME_REGEX.test(values.lastName)) {
        errors.lastName = "El apellido debe ser un apellido válido"
    }

    if (isNewUser) {
        if (!values.password) {
            errors.password = "Ingrese la contraseña";
        } else if (values.password.length < 8 || values.password.length > 20) {
            errors.password = "La contraseña debe tener entre 8 y 20 caracteres"
        } else if (!PASSWORD_REGEX.test(values.password)) {
            errors.password = "La contraseña debe tener al menos un caracter en minúscula, uno en mayúscula y un numéro";
        }
    }

    if (!values.birthdate) {
        errors.birthdate = "Ingrese la fecha de nacimiento";
    } else if (values.birthdate.isAfter(moment())) {
        errors.birthdate = "La fecha de nacimiento debe ser pasada"
    }

    if (!values.email) {
        errors.email = "Ingrese el correo electrónico";
    } else if (!EMAIL_REGEX.test(values.email)) {
        errors.email = "El correo electrónico debe ser un correo electrónico válido";
    }

    if (values.phoneNumber && !POSITIVE_INT_REGEX.test(values.phoneNumber)) {
        errors.phoneNumber = "El teléfono debe ser un número entero positivo";
    }

    if (values.memberNumber && !POSITIVE_INT_REGEX.test(values.memberNumber)) {
        errors.memberNumber = "El número de socio debe ser un número entero positivo";
    }

    if (!values.role && !permissions.length) {
        errors.role = ROLE_PERMISSIONS_ERROR_MESSAGE;
        errors.permissions = ROLE_PERMISSIONS_ERROR_MESSAGE;
    }

    return errors;
}