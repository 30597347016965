import moment from 'moment';
import 'moment/locale/es';
import { DNI_REGEX, NAME_REGEX, POSITIVE_NUMBER_REGEX } from '../../../constants/regex';


export default (values) => {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Ingrese el nombre";
    } else if (!NAME_REGEX.test(values.firstName)) {
        errors.firstName = "El nombre debe ser un nombre válido"
    }

    if (!values.lastName) {
        errors.lastName = "Ingrese el apellido";
    } else if (!NAME_REGEX.test(values.lastName)) {
        errors.lastName = "El apellido debe ser un nombre válido"
    }

    if (!values.dni) {
        errors.dni = "Ingrese el DNI";
    } else if (!DNI_REGEX.test(values.dni)) {
        errors.dni = "El DNI debe ser un número de 7 u 8 dígitos"
    }

    if (!values.date) {
        errors.date = "Ingrese la fecha de concurrencia";
    } else if (values.date.isBefore(moment(), 'day')) {
        errors.date = "La fecha de concurrencia debe ser actual o futura"
    }

    if (!values.inviter) {
        errors.inviter = "Ingrese el invitante";
    }

    if (values.invitationPrice) {
        if (!POSITIVE_NUMBER_REGEX.test(values.invitationPrice)) {
            errors.invitationPrice = "El precio debe ser un número positivo";
        }
    }

    return errors;
}