import { LETTERS_NUMBERS_SPACES_POINTS_REGEX } from "../../../constants/regex";


export default (values) => {
    let errors = {};

    if (!values.instance) {
        errors.instance = "Ingrese la instancia";
    }

    if (!values.rivalName) {
        errors.rivalName = "Ingrese el rival";
    } else if (!LETTERS_NUMBERS_SPACES_POINTS_REGEX.test(values.rivalName)) {
        errors.rivalName = "El nombre del rival sólo puede contener letras, números, espacios y puntos"
    }

    if (!values.date) {
        errors.date = "Seleccione una fecha";
    }

    return errors;
}