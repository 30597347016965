export default [
    {
        id: 'name',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'branch',
        field: 'name',
        label: 'Sede',
        minWidth: 100,
    },
    {
        id: 'shiftDuration',
        label: 'Duración del turno (en minutos)',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'requiredMembershipType',
        field: 'name',
        label: 'Tipo de Abono requerido',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    }
];
