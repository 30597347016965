import React, { useState, useEffect } from 'react';
import { handleResponse, get } from '../../utils/network';
import { genericOnRangeChange } from '../../utils/utils';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import 'moment/locale/es';
import { CALENDAR_MESSAGES } from '../common/CalendarMessages';

const localizer = momentLocalizer(moment);

const EventsContainer = props => {
    const [events, setEvents] = useState([]);
    const [currentDate] = useState(moment());
    const [date, setDate] = useState(moment().toDate());
    const [view, setView] = useState('month');

    const firstDayOfMonth = (date) => {
        return date.clone().startOf('month').toISOString();
    }
    const lastDayOfMonth = (date) => {
        return date.clone().endOf('month').add(1, 'days').toISOString();
    }

    const getEvents = (start, end) => {
        get(`events/?from=${start}&to=${end}`)
            .then(res => handleResponse(res, props))
            .then(parsed => {
                if (parsed.error) props.history.push('/home/events');
                else {
                    if (parsed.message.length) {

                        let responseEvents = parsed.message
                            .flatMap((eventsOfDay) => eventsOfDay.events)
                            .map((event) => ({
                                ...event,
                                eventDate: new Date(event.eventDate)
                            }));
                        setEvents(responseEvents);
                    }
                }
            });
    }

    useEffect(() => {
        getEvents(firstDayOfMonth(currentDate), lastDayOfMonth(currentDate));
    }, [currentDate]);


    const onRangeChange = async (start, end) => {
        await getEvents(start, end);
    };

    const onSelectEvent = (event) => props.history.push({ pathname: '/home/events/' + event._id, state: { event } });

    const onSelectSlot = (slotInfo) => {
        if (view === 'month') {
            onDrillDown(slotInfo.start, 'day')
        }
        else
            props.history.push({ pathname: '/home/events/new', state: { slotInfo } });
    }

    const onDrillDown = (newDate, newView, drilldownView) => {
        setDate(newDate);
        setView(newView);
        if (newView === 'day')
            genericOnRangeChange(onRangeChange)([newDate]);
    }

    const customDayPropGetter = date => {
        if (moment().isBefore(date)) {
            return {
                className: 'future-day',
                style: {
                    backgroundColor: '#FFFFFF'
                }
            }
        }
    }

    return (
        <>
            <Calendar
                selectable
                date={date}
                onNavigate={setDate}
                view={view}
                onView={setView}
                getNow={() => currentDate.toDate()}
                localizer={localizer}
                events={events}
                titleAccessor="event"
                startAccessor="eventDate"
                endAccessor={(event) => event.eventDate}
                allDayAccessor={() => false}
                messages={CALENDAR_MESSAGES}
                onRangeChange={genericOnRangeChange(onRangeChange, true)}
                onSelectEvent={onSelectEvent}
                onSelectSlot={onSelectSlot}
                onDrillDown={onDrillDown}
                dayPropGetter={customDayPropGetter}
                style={{ height: 800 }}
            />
        </>
    )
}

export default EventsContainer;