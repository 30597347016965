import React from 'react';
import { Layout } from '../components/common';
import { Route, Redirect, Switch } from 'react-router-dom';
import UserTableContainer from '../components/Users/UsersTable/UserTableContainer';
import UserFormContainer from '../components/Users/UserForm/UserFormContainer';
import SignupInvitationsFormContainer from "../components/Users/SignupInvitationsForm/SignupInvitationsFormContainer";
import RolesTableContainer from "../components/Roles/RolesTable/RolesTableContainer";
import RoleFormContainer from "../components/Roles/RoleForm/RoleFormContainer";
import SessionsList from "../components/Sessions/SessionsList";
import ProfileFormContainer from "../components/Profile/ProfileFormContainer";
import { getCookie } from '../utils/utils';
import TopicsContainer from '../components/Topics/TopicsContainer';
import TopicFormContainer from '../components/Topics/TopicForm/TopicFormContainer';
import NewsTableContainer from '../components/News/NewsTable/NewsTableContainer';
import NewsFormContainer from '../components/News/NewsForm/NewsFormContainer';
import EventsContainer from '../components/Events/EventsContainer'
import EventFormContainer from '../components/Events/EventForm/EventFormContainer'
import BranchesTableContainer from '../components/Branches/BranchesTable/BranchesTableContainer';
import BranchFormContainer from '../components/Branches/BranchForm/BranchFormContainer';
import MembershipsTableContainer from '../components/Memberships/MembershipsTable/MembershipsTableContainer';
import MembershipFormContainer from '../components/Memberships/MembershipForm/MembershipFormContainer';
import MembershipTypesContainer from '../components/MembershipTypes/MembershipTypesContainer';
import MembershipTypeFormContainer from '../components/MembershipTypes/MembershipTypeForm/MembershipTypeFormContainer';
import FAQsContainer from '../components/FAQs/FAQsContainer'
import FAQFormContainer from '../components/FAQs/FAQForm/FAQFormContainer';
import FacilitiesTableContainer from '../components/Facilities/FacilitiesTable/FacilitiesTableContainer';
import FacilityFormContainer from '../components/Facilities/FacilitiyForm/FacilityFormContainer';
import ReservationsContainer from '../components/Reservations/ReservationsTable/ReservationsContainer';
import ActivitiesTableContainer from '../components/Activities/ActivitiesTable/ActivitiesTableContainer';
import DisciplinesTableContainer from '../components/Disciplines/DisciplinesTable/DisciplinesTableContainer';
import DisciplineFormContainer from '../components/Disciplines/DisciplineForm/DisciplineFormContainer';
import ActivityFormContainer from '../components/Activities/ActivityForm/ActivityFormContainer';
import ActivityParticipantsTableContainer from '../components/Activities/ParticipantsCRUD/ActivityParticipantsTableContainer';
import SportTeamsTableContainer from '../components/SportTeams/SportTeamsTable/SportTeamsTableContainer';
import SportsTeamFormContainer from '../components/SportTeams/SportTeamForm/SportTeamFormContainer';
import TournamentsTableContainer from '../components/Tournaments/TournamentsTable/TournamentsTableContainer';
import TournamentFormContainer from '../components/Tournaments/TournamentForm/TournamentFormContainer';
import MatchesTableContainer from '../components/Matches/MatchesTable/MatchesTableContainer';
import MatchFormContainer from '../components/Matches/MatchForm/MatchFormContainer';
import InviteesTableContainer from '../components/Invitees/InviteesTable/InviteesTableContainer';
import InviteeFormContainer from '../components/Invitees/InviteeForm/InviteeFormContainer';
import ReportsContainer from '../components/Reports/ReportsContainer';
import Swal from 'sweetalert2';
import { useTheme } from '@material-ui/styles';
import FacilityTypesTableContainer from '../components/FacilityTypes/FacilityTypesTable/FacilityTypesTableContainer';
import FacilityTypeFormContainer from '../components/FacilityTypes/FacilityTypeForm/FacilityTypeFormContainer';

const getRouteOptions = () => JSON.parse(getCookie('routeOptions'));

const PrivateRoute = ({ component, path, exact = false }) => {
    const theme = useTheme();
    const routeOptions = getRouteOptions();
    if (routeOptions.some(routeOption => path.includes(routeOption.path))) {
        return <Route exact={exact} path={path} component={component} />
    } else {
        Swal.fire({
            icon: 'error',
            title: 'No tenés permiso para acceder a esta página',
            text: 'Por favor, contactá al administrador del sistema',
            confirmButtonColor: theme.palette.primary.main,
            customClass: {
                container: 'my-swal'
            }
        })
        return <Redirect to={routeOptions[0].path} />
    }
};

export default function Home(props) {
    const isLogged = getCookie("isLogged");
    return (
        !isLogged
            ? <Redirect from='/home/*' to='/login' />
            : <Layout history={props.history}>
                <Switch>
                    <PrivateRoute exact path='/home/users' component={UserTableContainer} />
                    <PrivateRoute exact path='/home/users/inviteForSignup' component={SignupInvitationsFormContainer} />
                    <PrivateRoute exact path='/home/users/:id' component={UserFormContainer} />
                    <PrivateRoute exact path='/home/roles' component={RolesTableContainer} />
                    <PrivateRoute exact path='/home/roles/:id' component={RoleFormContainer} />
                    <PrivateRoute exact path='/home/sessions' component={SessionsList} />
                    <Route exact path='/home/profile' component={ProfileFormContainer} />
                    <PrivateRoute exact path='/home/topics' component={TopicsContainer} />
                    <PrivateRoute exact path='/home/topics/:id' component={TopicFormContainer} />
                    <PrivateRoute exact path='/home/news' component={NewsTableContainer} />
                    <PrivateRoute exact path='/home/news/:id' component={NewsFormContainer} />
                    <PrivateRoute exact path='/home/events' component={EventsContainer} />
                    <PrivateRoute exact path='/home/events/:id' component={EventFormContainer} />
                    <PrivateRoute exact path='/home/branches' component={BranchesTableContainer} />
                    <PrivateRoute exact path='/home/branches/:id' component={BranchFormContainer} />
                    <PrivateRoute exact path='/home/memberships' component={MembershipsTableContainer} />
                    <PrivateRoute exact path='/home/memberships/:id' component={MembershipFormContainer} />
                    <PrivateRoute exact path='/home/membershipTypes' component={MembershipTypesContainer} />
                    <PrivateRoute exact path='/home/membershipTypes/:id' component={MembershipTypeFormContainer} />
                    <PrivateRoute exact path='/home/faqs' component={FAQsContainer} />
                    <PrivateRoute exact path='/home/faqs/:id' component={FAQFormContainer} />
                    <PrivateRoute exact path='/home/facilities' component={FacilitiesTableContainer} />
                    <PrivateRoute exact path='/home/facilities/:id' component={FacilityFormContainer} />
                    <PrivateRoute exact path='/home/facilities/:facilityId/reservations' component={ReservationsContainer} />
                    <PrivateRoute exact path='/home/facilityTypes' component={FacilityTypesTableContainer} />
                    <PrivateRoute exact path='/home/facilityTypes/:id' component={FacilityTypeFormContainer} />
                    <PrivateRoute exact path='/home/activities' component={ActivitiesTableContainer} />
                    <PrivateRoute exact path='/home/activities/:id' component={ActivityFormContainer} />
                    <PrivateRoute exact path='/home/activities/:id/participants' component={ActivityParticipantsTableContainer} />
                    <PrivateRoute exact path='/home/disciplines' component={DisciplinesTableContainer} />
                    <PrivateRoute exact path='/home/disciplines/:id' component={DisciplineFormContainer} />
                    <PrivateRoute exact path='/home/sportteams' component={SportTeamsTableContainer} />
                    <PrivateRoute exact path='/home/sportteams/:id' component={SportsTeamFormContainer} />
                    <PrivateRoute exact path='/home/sportteams/:id/tournaments' component={TournamentsTableContainer} />
                    <PrivateRoute exact path='/home/sportteams/:sportTeamId/tournaments/:id' component={TournamentFormContainer} />
                    <PrivateRoute exact path='/home/sportteams/:sportTeamId/tournaments/:id/matches' component={MatchesTableContainer} />
                    <PrivateRoute exact path='/home/sportteams/:sportTeamId/tournaments/:tournamentId/matches/:id' component={MatchFormContainer} />
                    <PrivateRoute exact path='/home/invitees' component={InviteesTableContainer} />
                    <PrivateRoute exact path='/home/invitees/:id' component={InviteeFormContainer} />
                    <PrivateRoute exact path='/home/reports' component={ReportsContainer} />
                    <Redirect exact from="/home" to={getRouteOptions()[0].path} />
                    <Redirect from='/home/*' to='/404' />
                </Switch>
            </Layout>
    )
}
