import React, { useEffect, useState } from 'react';
import { useFormValidation } from '../../components/common';
import { handleResponse, post, swal500 } from '../../utils/network';
import SignupComponent from './SignupComponent';
import validateMember from './validator';
import jwt from 'jsonwebtoken';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { SET_CLUB } from '../../utils/state/store';
import { MuiThemeProvider, createMuiTheme, useTheme } from '@material-ui/core';
import { clubsDef } from '../Onboarding/OnboardingContainer';

export const FIELDS = {
    firstName: "Nombre",
    lastName: "Apellido",
    memberNumber: "Número de socio (opcional)",
    birthdate: "Fecha de nacimiento",
    phoneNumber: "Número de celular (opcional)",
    email: "Correo electrónico",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    role: "Rol"
};

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    memberNumber: '',
    phoneNumber: '',
    birthdate: null,
    email: '',
    password: '',
    confirmPassword: ''
};

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SignupContainer = (props) => {
    const dispatch = useDispatch();
    const query = useQuery();
    const [clubShortName, setClubShortName] = useState('');
    const [token, setToken] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const theme = useTheme();
    const [clubSpecificTheme, setClubSpecificTheme] = useState(theme);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues,
        handleDateChange
    } = useFormValidation(INITIAL_STATE, values => validateMember(values));

    useEffect(() => {
        setClubSpecificTheme(theme);
    }, [theme]);

    useEffect(() => {
        const queryToken = query.get('token');
        if (!queryToken) {
            props.history.push('/login');
            return;
        }
        const { clubShortName: shortName, emailAddress: email } = jwt.decode(queryToken);
        if (!(shortName && email)) {
            props.history.push('/login');
            return;
        }
        setClubShortName(shortName);
        setToken(queryToken);
        resetValues({ email });

        const clubData = clubsDef[shortName];
        if (clubData) {
            const clubStyle = createMuiTheme(
                {
                    ...theme,
                    ...clubData.theme
                }
            );
            setClubSpecificTheme(clubStyle);
        }
    }, []);


    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                errors[field.name] = field.message.replaceAll(field.name, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            post('auth/signup', {
                token,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                birthdate: values.birthdate,
                ...(values.memberNumber && { memberNumber: values.memberNumber }),
                ...(values.phoneNumber && { phoneNumber: values.phoneNumber })
            })
                .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                .then(res => {
                    setSubmitting(false);
                    dispatch({ type: SET_CLUB, club: clubShortName });
                    props.history.push({
                        pathname: '/login'
                    });
                })
                .catch(err => {
                    setSubmitting(false);
                    if (err !== 400) {
                        swal500(err);
                    }
                });
        }
    };


    return <MuiThemeProvider theme={clubSpecificTheme}>
        <SignupComponent
            errors={errors}
            clubShortName={clubShortName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleResult={handleResult}
            handleDateChange={handleDateChange}
            submitting={submitting}
            values={values}
        />
    </MuiThemeProvider>
}


export default SignupContainer;