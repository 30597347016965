import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateMembership from './validator';
import * as Swal from "sweetalert2";
import MembershipFormComponent from './MembershipFormComponent';
import { getChangedValues, objectIsEmpty } from '../../../utils/utils';

export const FIELDS = {
    type: "Tipo de Abono",
    description: "Descripción",
    price: "Precio general",
    ageFrom: "Edad mínima (opcional)",
    ageTo: "Edad máxima (opcional)",
    lifelongPrice: "Precio vitalicio",
    duration: "Duración"
};

const INITIAL_STATE = {
    _id: '',
    type: null,
    description: '',
    price: '',
    ageFrom: '',
    ageTo: '',
    lifelongPrice: '',
    duration: '',
    hasPendingCancellation: false,
    erasedOnNextPeriod: false
};

const MembershipFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateMembership(values));
    const isNewMembership = objectIsEmpty(data);

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/memberships');

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
            return;
        } else if (props.location.state) {

            const stateKeys = Object.keys(INITIAL_STATE);
            const { state } = props.location

            const validKeys = {}

            //copy only valid fields
            stateKeys.forEach(k => {
                if (state.hasOwnProperty(k))
                    validKeys[k] = state[k]
            });

            if (Object.keys(validKeys).length === stateKeys.length) {
                setData(state)
                resetValues(state);
                setLoading(false);
                return;
            }
        }

        handleClose();
    }, [props, resetValues]);


    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar abono",
            text: `¿Estás seguro de que querés eliminar el abono '${values.description}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar abono',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`memberships/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/memberships',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {

        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewMembership) {
                const body = {
                    'type': values.type._id,
                    'description': values.description,
                    'price': values.price,
                    'lifelongPrice': values.lifelongPrice,
                    'duration': values.duration
                };

                if (values.ageFrom)
                    Object.assign(body, { ageFrom: values.ageFrom });

                if (values.ageTo)
                    Object.assign(body, { ageTo: values.ageTo });

                post('memberships', body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/memberships',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const changedValues = getChangedValues(data, values);
                if (changedValues.length === 0) {
                    props.history.push('/home/memberships');
                    return;
                }

                const body = {}

                //remove uneditable fields if they were edited through the console
                changedValues.filter(e => e != "periodicity" && e != "ageTo" && e != "ageFrom").forEach(value => {
                    if (value === "type")
                        Object.assign(body, { [value]: values[value]._id || "" });
                    else
                        Object.assign(body, { [value]: values[value] });
                });

                patch(`memberships/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/memberships',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    const isEditable = () => !values.erasedOnNextPeriod

    return (
        <MembershipFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewMembership={isNewMembership}
            isEditable={isEditable()}
        />
    );
};

export default MembershipFormContainer;