import React from "react";
import { IconButton, TextField, withStyles } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./EventFormContainer";
import { FormLayout } from "../../common";
import moment from 'moment';
import 'moment/locale/es';
import CustomAutocomplete from "../../common/CustomAutocomplete";

const EventFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleDateChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        loadingBranches,
        loadingTopics,
        values,
        allBranches,
        allTopics,
        editMode,
        setEditMode,
        isNewEvent,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewEvent &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    const handleAutocompleteChange = (name) => (e, value) => {
        handleChange({ target: { name, value } })
    }

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewEvent}
            dataKey="Evento"
        >
            <TextField
                name='event'
                error={errors.event !== undefined}
                helperText={errors.event}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.event}
                label={FIELDS.event}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='description'
                error={errors.description !== undefined}
                helperText={errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                label={FIELDS.description}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
                multiline={true}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name="eventDate"
                type="datetime-local"
                error={errors.eventDate !== undefined}
                helperText={errors.eventDate}
                onChange={handleDateChange}
                value={moment(values.eventDate).format('YYYY-MM-DDTHH:mm')}
                label={FIELDS.eventDate}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}

            />
            {editMode && <div className={classes.divider} />}
            <CustomAutocomplete
                autoComplete
                id="branch"
                onChange={handleAutocompleteChange("branch")}
                loading={loadingBranches}
                loadingText="Buscando Sedes..."
                noOptionsText='No hay Sedes'
                options={allBranches}
                value={values.branch}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name || ""}
                readOnly={!editMode}
                label={FIELDS.branch}
                error={errors.branch}
                className={classes.textField}
            />
            {editMode && <div className={classes.divider} />}
            <CustomAutocomplete
                multiple
                autoComplete
                id="topics"
                onChange={handleAutocompleteChange("topics")}
                loading={loadingTopics}
                loadingText="Buscando Tópicos..."
                noOptionsText='No hay Tópicos'
                options={allTopics}
                value={values.topics}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.title || ""}
                readOnly={!editMode}
                label={FIELDS.topics}
                error={errors.topics}
                className={classes.textField}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(EventFormComponent);