import { NAME_WITH_DASH_REGEX, POSITIVE_INT_REGEX, POSITIVE_NUMBER_REGEX, TIME_OF_DAY_24_HOURS_REGEX } from '../../../constants/regex';
import { validateAgeFields } from "../../../utils/utils";

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Ingrese el nombre";
    } else if (!NAME_WITH_DASH_REGEX.test(values.name)) {
        errors.name = "El nombre debe ser un nombre válido"
    }

    if (!values.description) {
        errors.description = "Ingrese la descripción";
    }

    if (typeof values.detailImage == "object" && !values.detailImage.type.includes("image/")) {
        errors.detailImage = "Debe cargar una imagen"
    }

    if (Array.isArray(values.schedules)) {

        if (values.schedules.length === 0)
            errors.schedules = "Defina al menos un horario"
    }

    if (values.newStartTime) {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newStartTime)) {
            errors.newStartTime = "El horario debe estar en formato 24 horas."
        }
    }

    if (values.newEndTime) {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newEndTime)) {
            errors.newEndTime = "El horario debe estar en formato 24 horas."
        }
    }

    if (!values.price) {
        errors.price = "Ingrese el precio";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.price)) {
        errors.price = "El precio debe ser un número no negativo"
    }

    if (!values.lifelongPrice) {
        errors.lifelongPrice = "Ingrese el precio para vitalicios";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.lifelongPrice)) {
        errors.lifelongPrice = "El precio para vitalicios debe ser un número no negativo"
    }

    validateAgeFields(values, errors);

    if (!values.discipline) {
        errors.discipline = "Seleccione una disciplina asociada"
    }

    if (values.enrollmentSlots && !POSITIVE_INT_REGEX.test(values.enrollmentSlots)) {
        errors.enrollmentSlots = "La cantidad máxima de inscriptos debe ser un número no negativo"
    }

    return errors;
}