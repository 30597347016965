import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500 } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateMembership from './validator';
import MembershipTypeFormComponent from './MembershipTypeFormComponent';
import { getChangedValues, objectIsEmpty } from '../../../utils/utils';

export const FIELDS = {
    name: "Nombre"
};

const INITIAL_STATE = {
    _id: '',
    name: '',
    detailImage: ''
};

const MembershipTypeFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [localImagePath, setLocalImagePath] = useState(null);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateMembership(values));
    const isNewMembershipType = objectIsEmpty(data);

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/membershipTypes');

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
            return;
        } else if (props.location.state) {

            const stateKeys = Object.keys(INITIAL_STATE);
            const { state } = props.location

            const validKeys = {}
            //copy only valid fields
            stateKeys.forEach(k => {
                if (state.hasOwnProperty(k))
                    validKeys[k] = state[k]
            });
            if (Object.keys(validKeys).length === stateKeys.length) {
                setData(state)
                resetValues(state);
                setLoading(false);
                return;
            }
        }
        handleClose();
    }, [props, resetValues]);

    const handleResult = (e) => {

        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewMembershipType) {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('detailImage', values.detailImage);

                post('membershipTypes', formData, true, { 'Accept': 'Application/json' }, true)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/membershipTypes',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const changedValues = getChangedValues(data, values);
                if (changedValues.length === 0) {
                    props.history.push('/home/membershipTypes');
                    return;
                }

                const formData = new FormData();

                changedValues.forEach(value => {
                    formData.append([value], values[value]);
                });

                patch(`membershipTypes/${data._id}`, formData, true, { 'Accept': 'Application/json' }, true)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/membershipTypes',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    const handleImageChange = ({ target }) => {

        handleChange({
            target: {
                name: "detailImage",
                value: target.files[0]
            }
        })
    }

    const detailImage = values.detailImage;

    useEffect(() => {
        if (typeof detailImage == "object")
            setLocalImagePath(URL.createObjectURL(detailImage))

    }, [detailImage]);

    useEffect(() => {

        handleBlur({
            target: {
                name: "detailImage"
            }
        })

    }, [localImagePath]);

    const isEditable = () => !values.erasedOnNextPeriod

    return (
        <MembershipTypeFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleImageChange={handleImageChange}
            handleResult={handleResult}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewMembershipType={isNewMembershipType}
            localImagePath={localImagePath}
            isEditable={isEditable()}
        />
    );
};

export default MembershipTypeFormContainer;